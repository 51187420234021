import React, { useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { CookieContext } from '../context/CookieProvider';

import './thank-you.scss'

const CONSIGNMENT_PARAM = 'Mail_In_Consignment'

const ThankYouPage = () => {
  const context = useContext(CookieContext)

  return (
    <Layout hideActions>
      <SEO title="Thank You" />
      <div className="thank-you-header">
        <div className="thank-you-header__text-container">
          <h1 className="thank-you-header__text">Thank You!</h1>
        </div>
      </div>
      <div className="thank-you-layout">
        {context && context.tfa_60 === CONSIGNMENT_PARAM ?
          (
            <div className="terms narrow-wrapper">
              <div id="mail-in" className="soft-hide" style={{ display: 'block' }}>
                <h2>What to Expect Next</h2>
                <span className="dash"></span>
                <ul className="next-list">
                  <li className="next-list__item next-list__item--highlight"><span className="next-list__number">1</span> You will receive an email with a link to print your shipping label and packing slip.</li>
                  <li className="next-list__item"><span className="next-list__number">2</span> After we receive your package our experts will photograph and write descriptions detailing your items.</li>
                  <li className="next-list__item"><span className="next-list__number">3</span> Our Expert Curators will look for the right opportunity to list your items in the best sale possible.</li>
                  <li className="next-list__item"><span className="next-list__number">4</span> You will be notified when your items are listed on ebth.com.</li>
                  <li className="next-list__item"><span className="next-list__number">5</span> Pending your items closing you will receive a check in approximately 20 business days.</li>
                </ul>
              </div>
              {/* <div id="default-content" className="soft-hide" style={{ minHeight: '15rem' }}>
                <p>Thank you for agreeing to our Terms &amp; Conditions. We will be in touch shortly.</p>
              </div> */}
            </div>
          ) : (
            <div className="thank-you-default-body">
              <p>
                One of our sale consultants is reviewing your information and will contact you via the phone number you provided as soon as possible. In the
                meantime, get to know EBTH sellers through their stories.
              </p>
            </div>
          )
        }
      </div>
    </Layout>
  )
}

export default ThankYouPage

